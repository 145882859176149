<template>
  <div class="activity">
    <img
      src="https://cdn.bubbleplan.cn/static/home/back_cc.png"
      alt=""
      class="activity-back"
      @click="back"
    />
    <div class="activity-box flex">
      <img
        src="https://cdn.bubbleplan.cn/m_bubble/home/Group103712x.png"
        alt=""
        class="activity-box-img"
        @click="secondlevelButton('/lashin/lashin')"
      />
      <img
        src="https://cdn.bubbleplan.cn/m_bubble/home/Group103632x.png"
        alt=""
        class="activity-box-img"
        @click="secondlevelButton('/home/assignment')"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    secondlevelButton(url) {
      this.$router.push(url);
    },
    back() {
      history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.activity {
  width: 100%;
  height: calc(100vh - 94px);
  position: relative;
  .activity-back {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 42px;
    left: 10%;
  }
  .activity-back {
    cursor: pointer;
  }
  &-box {
    width: 100%;
    height: 1080px;
    overflow: auto;
    background: url("https://cdn.bubbleplan.cn/m_bubble/home/eqwadscxz.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 181px 0;
    padding-left: 254px;
    &-img:hover {
      cursor: pointer;
    }
    &-img {
      width: 692px;
      height: 400px;
      margin-right: 30px;
    }
  }
}
</style>